const countryCodes = [
    { name: 'United States', dial_code: '+1', code: 'US', emoji: '🇺🇸' },
    { name: 'United Kingdom', dial_code: '+44', code: 'GB', emoji: '🇬🇧' },
    { name: 'India', dial_code: '+91', code: 'IN', emoji: '🇮🇳' },
    { name: 'Australia', dial_code: '+61', code: 'AU', emoji: '🇦🇺' },
    { name: 'Germany', dial_code: '+49', code: 'DE', emoji: '🇩🇪' },
    { name: 'France', dial_code: '+33', code: 'FR', emoji: '🇫🇷' },
    { name: 'Japan', dial_code: '+81', code: 'JP', emoji: '🇯🇵' },
    { name: 'China', dial_code: '+86', code: 'CN', emoji: '🇨🇳' },
    { name: 'Brazil', dial_code: '+55', code: 'BR', emoji: '🇧🇷' },
    { name: 'Mexico', dial_code: '+52', code: 'MX', emoji: '🇲🇽' },
    { name: 'Italy', dial_code: '+39', code: 'IT', emoji: '🇮🇹' },
    { name: 'Spain', dial_code: '+34', code: 'ES', emoji: '🇪🇸' },
    { name: 'Russia', dial_code: '+7', code: 'RU', emoji: '🇷🇺' },
    { name: 'South Korea', dial_code: '+82', code: 'KR', emoji: '🇰🇷' },
    { name: 'Netherlands', dial_code: '+31', code: 'NL', emoji: '🇳🇱' },
    { name: 'Sweden', dial_code: '+46', code: 'SE', emoji: '🇸🇪' },
    { name: 'Switzerland', dial_code: '+41', code: 'CH', emoji: '🇨🇭' },
    { name: 'Norway', dial_code: '+47', code: 'NO', emoji: '🇳🇴' },
    { name: 'Denmark', dial_code: '+45', code: 'DK', emoji: '🇩🇰' },
    { name: 'Finland', dial_code: '+358', code: 'FI', emoji: '🇫🇮' },
    { name: 'New Zealand', dial_code: '+64', code: 'NZ', emoji: '🇳🇿' },
    { name: 'Singapore', dial_code: '+65', code: 'SG', emoji: '🇸🇬' },
    { name: 'Ireland', dial_code: '+353', code: 'IE', emoji: '🇮🇪' },
    { name: 'South Africa', dial_code: '+27', code: 'ZA', emoji: '🇿🇦' },
    { name: 'Belgium', dial_code: '+32', code: 'BE', emoji: '🇧🇪' },
    { name: 'Portugal', dial_code: '+351', code: 'PT', emoji: '🇵🇹' },
    { name: 'Greece', dial_code: '+30', code: 'GR', emoji: '🇬🇷' },
    { name: 'Austria', dial_code: '+43', code: 'AT', emoji: '🇦🇹' },
    { name: 'Poland', dial_code: '+48', code: 'PL', emoji: '🇵🇱' },
    { name: 'Turkey', dial_code: '+90', code: 'TR', emoji: '🇹🇷' },
    { name: 'Argentina', dial_code: '+54', code: 'AR', emoji: '🇦🇷' },
    { name: 'Chile', dial_code: '+56', code: 'CL', emoji: '🇨🇱' },
    { name: 'Colombia', dial_code: '+57', code: 'CO', emoji: '🇨🇴' },
    { name: 'Peru', dial_code: '+51', code: 'PE', emoji: '🇵🇪' },
    { name: 'Venezuela', dial_code: '+58', code: 'VE', emoji: '🇻🇪' },
    { name: 'Malaysia', dial_code: '+60', code: 'MY', emoji: '🇲🇾' },
    { name: 'Indonesia', dial_code: '+62', code: 'ID', emoji: '🇮🇩' },
    { name: 'Thailand', dial_code: '+66', code: 'TH', emoji: '🇹🇭' },
    { name: 'Vietnam', dial_code: '+84', code: 'VN', emoji: '🇻🇳' },
    { name: 'Philippines', dial_code: '+63', code: 'PH', emoji: '🇵🇭' },
    { name: 'Egypt', dial_code: '+20', code: 'EG', emoji: '🇪🇬' },
    { name: 'Israel', dial_code: '+972', code: 'IL', emoji: '🇮🇱' },
    { name: 'Saudi Arabia', dial_code: '+966', code: 'SA', emoji: '🇸🇦' },
    { name: 'United Arab Emirates', dial_code: '+971', code: 'AE', emoji: '🇦🇪' },
    { name: 'Pakistan', dial_code: '+92', code: 'PK', emoji: '🇵🇰' },
    { name: 'Bangladesh', dial_code: '+880', code: 'BD', emoji: '🇧🇩' },
    { name: 'Nigeria', dial_code: '+234', code: 'NG', emoji: '🇳🇬' },
    { name: 'Kenya', dial_code: '+254', code: 'KE', emoji: '🇰🇪' },
    { name: 'Ghana', dial_code: '+233', code: 'GH', emoji: '🇬🇭' },
    { name: 'Afghanistan', dial_code: '+93', code: 'AF', emoji: '🇦🇫' },
    { name: 'Albania', dial_code: '+355', code: 'AL', emoji: '🇦🇱' },
    { name: 'Algeria', dial_code: '+213', code: 'DZ', emoji: '🇩🇿' },
    { name: 'Andorra', dial_code: '+376', code: 'AD', emoji: '🇦🇩' },
    { name: 'Angola', dial_code: '+244', code: 'AO', emoji: '🇦🇴' },
    { name: 'Antigua and Barbuda', dial_code: '+1268', code: 'AG', emoji: '🇦🇬' },
    { name: 'Argentina', dial_code: '+54', code: 'AR', emoji: '🇦🇷' },
    { name: 'Armenia', dial_code: '+374', code: 'AM', emoji: '🇦🇲' },
    { name: 'Australia', dial_code: '+61', code: 'AU', emoji: '🇦🇺' },
    { name: 'Austria', dial_code: '+43', code: 'AT', emoji: '🇦🇹' },
    { name: 'Azerbaijan', dial_code: '+994', code: 'AZ', emoji: '🇦🇿' },
    { name: 'Bahamas', dial_code: '+1242', code: 'BS', emoji: '🇧🇸' },
    { name: 'Bahrain', dial_code: '+973', code: 'BH', emoji: '🇧🇭' },
    { name: 'Bangladesh', dial_code: '+880', code: 'BD', emoji: '🇧🇩' },
    { name: 'Barbados', dial_code: '+1246', code: 'BB', emoji: '🇧🇧' },
    { name: 'Belarus', dial_code: '+375', code: 'BY', emoji: '🇧🇾' },
    { name: 'Belgium', dial_code: '+32', code: 'BE', emoji: '🇧🇪' },
    { name: 'Belize', dial_code: '+501', code: 'BZ', emoji: '🇧🇿' },
    { name: 'Benin', dial_code: '+229', code: 'BJ', emoji: '🇧🇯' },
    { name: 'Bhutan', dial_code: '+975', code: 'BT', emoji: '🇧🇹' },
    { name: 'Bolivia', dial_code: '+591', code: 'BO', emoji: '🇧🇴' },
    { name: 'Bosnia and Herzegovina', dial_code: '+387', code: 'BA', emoji: '🇧🇦' },
    { name: 'Botswana', dial_code: '+267', code: 'BW', emoji: '🇧🇼' },
    { name: 'Brazil', dial_code: '+55', code: 'BR', emoji: '🇧🇷' },
    { name: 'Brunei', dial_code: '+673', code: 'BN', emoji: '🇧🇳' },
    { name: 'Bulgaria', dial_code: '+359', code: 'BG', emoji: '🇧🇬' },
    { name: 'Burkina Faso', dial_code: '+226', code: 'BF', emoji: '🇧🇫' },
    { name: 'Burundi', dial_code: '+257', code: 'BI', emoji: '🇧🇮' },
    { name: 'Cambodia', dial_code: '+855', code: 'KH', emoji: '🇰🇭' },
    { name: 'Cameroon', dial_code: '+237', code: 'CM', emoji: '🇨🇲' },
    { name: 'Canada', dial_code: '+1', code: 'CA', emoji: '🇨🇦' },
    { name: 'Cape Verde', dial_code: '+238', code: 'CV', emoji: '🇨🇻' },
    { name: 'Central African Republic', dial_code: '+236', code: 'CF', emoji: '🇨🇫' },
    { name: 'Chad', dial_code: '+235', code: 'TD', emoji: '🇹🇩' },
    { name: 'Chile', dial_code: '+56', code: 'CL', emoji: '🇨🇱' },
    { name: 'China', dial_code: '+86', code: 'CN', emoji: '🇨🇳' },
    { name: 'Colombia', dial_code: '+57', code: 'CO', emoji: '🇨🇴' },
    { name: 'Comoros', dial_code: '+269', code: 'KM', emoji: '🇰🇲' },
    { name: 'Congo', dial_code: '+242', code: 'CG', emoji: '🇨🇬' },
    { name: 'Costa Rica', dial_code: '+506', code: 'CR', emoji: '🇨🇷' },
    { name: 'Croatia', dial_code: '+385', code: 'HR', emoji: '🇭🇷' },
    { name: 'Cuba', dial_code: '+53', code: 'CU', emoji: '🇨🇺' },
    { name: 'Cyprus', dial_code: '+357', code: 'CY', emoji: '🇨🇾' },
    { name: 'Czech Republic', dial_code: '+420', code: 'CZ', emoji: '🇨🇿' },
    { name: 'Denmark', dial_code: '+45', code: 'DK', emoji: '🇩🇰' },
    { name: 'Djibouti', dial_code: '+253', code: 'DJ', emoji: '🇩🇯' },
    { name: 'Dominica', dial_code: '+1767', code: 'DM', emoji: '🇩🇲' },
    { name: 'Dominican Republic', dial_code: '+1849', code: 'DO', emoji: '🇩🇴' },
    { name: 'East Timor', dial_code: '+670', code: 'TL', emoji: '🇹🇱' },
    { name: 'Ecuador', dial_code: '+593', code: 'EC', emoji: '🇪🇨' },
    { name: 'Egypt', dial_code: '+20', code: 'EG', emoji: '🇪🇬' },
    { name: 'El Salvador', dial_code: '+503', code: 'SV', emoji: '🇸🇻' },
    { name: 'Equatorial Guinea', dial_code: '+240', code: 'GQ', emoji: '🇬🇶' },
    { name: 'Eritrea', dial_code: '+291', code: 'ER', emoji: '🇪🇷' },
    { name: 'Estonia', dial_code: '+372', code: 'EE', emoji: '🇪🇪' },
    { name: 'Ethiopia', dial_code: '+251', code: 'ET', emoji: '🇪🇹' },
    { name: 'Fiji', dial_code: '+679', code: 'FJ', emoji: '🇫🇯' },
    { name: 'Finland', dial_code: '+358', code: 'FI', emoji: '🇫🇮' },
    { name: 'France', dial_code: '+33', code: 'FR', emoji: '🇫🇷' },
    { name: 'Gabon', dial_code: '+241', code: 'GA', emoji: '🇬🇦' },
    { name: 'Gambia', dial_code: '+220', code: 'GM', emoji: '🇬🇲' },
    { name: 'Georgia', dial_code: '+995', code: 'GE', emoji: '🇬🇪' },
    { name: 'Germany', dial_code: '+49', code: 'DE', emoji: '🇩🇪' },
    { name: 'Ghana', dial_code: '+233', code: 'GH', emoji: '🇬🇭' },
    { name: 'Greece', dial_code: '+30', code: 'GR', emoji: '🇬🇷' },
    { name: 'Grenada', dial_code: '+1473', code: 'GD', emoji: '🇬🇩' },
    { name: 'Guatemala', dial_code: '+502', code: 'GT', emoji: '🇬🇹' },
    { name: 'Guinea', dial_code: '+224', code: 'GN', emoji: '🇬🇳' },
    { name: 'Guinea-Bissau', dial_code: '+245', code: 'GW', emoji: '🇬🇼' },
    { name: 'Guyana', dial_code: '+592', code: 'GY', emoji: '🇬🇾' },
    { name: 'Haiti', dial_code: '+509', code: 'HT', emoji: '🇭🇹' },
    { name: 'Honduras', dial_code: '+504', code: 'HN', emoji: '🇭🇳' },
    { name: 'Hungary', dial_code: '+36', code: 'HU', emoji: '🇭🇺' },
    { name: 'Iceland', dial_code: '+354', code: 'IS', emoji: '🇮🇸' },
    { name: 'India', dial_code: '+91', code: 'IN', emoji: '🇮🇳' },
    { name: 'Indonesia', dial_code: '+62', code: 'ID', emoji: '🇮🇩' },
    { name: 'Iran', dial_code: '+98', code: 'IR', emoji: '🇮🇷' },
    { name: 'Iraq', dial_code: '+964', code: 'IQ', emoji: '🇮🇶' },
    { name: 'Ireland', dial_code: '+353', code: 'IE', emoji: '🇮🇪' },
    { name: 'Israel', dial_code: '+972', code: 'IL', emoji: '🇮🇱' },
    { name: 'Italy', dial_code: '+39', code: 'IT', emoji: '🇮🇹' },
    { name: 'Jamaica', dial_code: '+1876', code: 'JM', emoji: '🇯🇲' },
    { name: 'Japan', dial_code: '+81', code: 'JP', emoji: '🇯🇵' },
    { name: 'Jordan', dial_code: '+962', code: 'JO', emoji: '🇯🇴' },
    { name: 'Kazakhstan', dial_code: '+7', code: 'KZ', emoji: '🇰🇿' },
    { name: 'Kenya', dial_code: '+254', code: 'KE', emoji: '🇰🇪' },
    { name: 'Kiribati', dial_code: '+686', code: 'KI', emoji: '🇰🇮' },
    { name: 'Korea, North', dial_code: '+850', code: 'KP', emoji: '🇰🇵' },
    { name: 'Korea, South', dial_code: '+82', code: 'KR', emoji: '🇰🇷' },
    { name: 'Kuwait', dial_code: '+965', code: 'KW', emoji: '🇰🇼' },
    { name: 'Kyrgyzstan', dial_code: '+996', code: 'KG', emoji: '🇰🇬' },
    { name: 'Laos', dial_code: '+856', code: 'LA', emoji: '🇱🇦' },
    { name: 'Latvia', dial_code: '+371', code: 'LV', emoji: '🇱🇻' },
    { name: 'Lebanon', dial_code: '+961', code: 'LB', emoji: '🇱🇧' },
    { name: 'Lesotho', dial_code: '+266', code: 'LS', emoji: '🇱🇸' },
    { name: 'Liberia', dial_code: '+231', code: 'LR', emoji: '🇱🇷' },
    { name: 'Libya', dial_code: '+218', code: 'LY', emoji: '🇱🇾' },
    { name: 'Liechtenstein', dial_code: '+423', code: 'LI', emoji: '🇱🇮' },
    { name: 'Lithuania', dial_code: '+370', code: 'LT', emoji: '🇱🇹' },
    { name: 'Luxembourg', dial_code: '+352', code: 'LU', emoji: '🇱🇺' },
    { name: 'Macedonia', dial_code: '+389', code: 'MK', emoji: '🇲🇰' },
    { name: 'Madagascar', dial_code: '+261', code: 'MG', emoji: '🇲🇬' },
    { name: 'Malawi', dial_code: '+265', code: 'MW', emoji: '🇲🇼' },
    { name: 'Malaysia', dial_code: '+60', code: 'MY', emoji: '🇲🇾' },
    { name: 'Maldives', dial_code: '+960', code: 'MV', emoji: '🇲🇻' },
    { name: 'Mali', dial_code: '+223', code: 'ML', emoji: '🇲🇱' },
    { name: 'Malta', dial_code: '+356', code: 'MT', emoji: '🇲🇹' },
    { name: 'Marshall Islands', dial_code: '+692', code: 'MH', emoji: '🇲🇭' },
    { name: 'Mauritania', dial_code: '+222', code: 'MR', emoji: '🇲🇷' },
    { name: 'Mauritius', dial_code: '+230', code: 'MU', emoji: '🇲🇺' },
    { name: 'Mexico', dial_code: '+52', code: 'MX', emoji: '🇲🇽' },
    { name: 'Micronesia', dial_code: '+691', code: 'FM', emoji: '🇫🇲' },
    { name: 'Moldova', dial_code: '+373', code: 'MD', emoji: '🇲🇩' },
    { name: 'Monaco', dial_code: '+377', code: 'MC', emoji: '🇲🇨' },
    { name: 'Mongolia', dial_code: '+976', code: 'MN', emoji: '🇲🇳' }
];


export default countryCodes;
